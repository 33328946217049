export default {
    namespaced: true,
    state: () => ({
        chinhSuaSoatVe: false,
        resetSoDoCho: 0,
        reloadSoDoCho: 0,

        choDaChon: [],
        chuyenDiGanDay: null,
        componentKey: 0,
        queryChoNgoi: {},
    }),
    getters: {
        chinhSuaSoatVe: (state) => state.chinhSuaSoatVe,
        resetSoDoCho: (state) => state.resetSoDoCho,
        reloadSoDoCho: (state) => state.reloadSoDoCho,

        choDaChon: (state) => state.choDaChon,
        chuyenDiGanDay: (state) => state.chuyenDiGanDay,
        componentKey: (state) => state.componentKey,
    },
    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
        setChinhSuaSoatVe(state, value) {
            state.chinhSuaSoatVe = value;
            state.componentKey = state.componentKey + 1;
        },
        resetSoDoCho(state) {
            state.resetSoDoCho++;
        },
        reloadSoDoCho(state) {
            state.reloadSoDoCho++;
        },

        setChoDaChon(state, value) {
            state.choDaChon = value;
        },
        setChuyenDiGanDay(state, value) {
            state.chuyenDiGanDay = value;
        },
        setComponentKey(state, value) {
            state.componentKey = state.componentKey + 1;
        },
    },

    actions: {
        async layDSDiemDungTheoChuyenDi(state, guidChuyenDi) {
            if (!guidChuyenDi) {
                throw new Error("Không có guidChuyenDi");
            }
            let rs = await $Core.Api.DiemXuongCuaKhachHang(
                window.$i18n.global.t("url.DanhSachDiemDungTheoChuyenDi"),
                { guidChuyenDi: guidChuyenDi }
            ).Get();
            return rs;
        },
        async layDSDiemDungTheoLoTrinh(state, guidLoTrinh) {
            if (!guidLoTrinh) {
                throw new Error("Không có guidLoTrinh");
            }
            let rs = await $Core.Api.QuanLyThongTinVe(
                window.$i18n.global.t("url.DanhSachDiemDungCuaLoTrinh"),
                { guidLoTrinh: guidLoTrinh }
            ).Get();
            return rs;
        },
        async layDSGiaVeGoiYTheoLoTrinh(state, guidLoTrinh) {
            try {
                if (!guidLoTrinh) {
                    throw new Error("Không có guidLoTrinh");
                }
                let rs = await $Core.Api.ThongTin(
                    window.$i18n.global.t("urlQLTTVe.LayDonGiaTheoTuyen"),
                    { idLoTrinh: guidLoTrinh }
                ).Get();
                return rs.Data;
            } catch (error) {
                console.log("🚀 error", error);
            }
        },
        async layDiemXuongHienTaiCuaChoNgoiChuyenDi(
            state,
            { guidChuyenDi, guidChoNgoi }
        ) {
            if (!guidChuyenDi) {
                throw new Error("Không có guidChuyenDi");
            }
            if (!guidChoNgoi) {
                throw new Error("Không có guidChoNgoi");
            }
            let rs = await $Core.Api.DiemXuongCuaKhachHang(
                window.$i18n.global.t("url.DiemXuongHienTaiCuaChoNgoiChuyenDi"),
                { guidChuyenDi: guidChuyenDi, guidChoNgoi: guidChoNgoi }
            ).Get();
            return rs;
        },
        async layGoiYDanhSachDiemXuongTheoChoNgoiChuyenDi(
            state,
            { guidChuyenDi, guidChoNgoi }
        ) {
            if (!guidChuyenDi) {
                throw new Error("Không có guidChuyenDi");
            }
            if (!guidChoNgoi) {
                throw new Error("Không có guidChoNgoi");
            }
            let rs = await $Core.Api.DiemXuongCuaKhachHang(
                window.$i18n.global.t(
                    "url.GoiYDanhSachDiemXuongTheoChoNgoiChuyenDi"
                ),
                { guidChuyenDi: guidChuyenDi, guidChoNgoi: guidChoNgoi }
            ).Get();
            return rs;
        },
        async layGoiYDanhSachDiemXuongTheoSoDienThoaiVaChuyenDi(
            state,
            { guidChuyenDi, soDienThoai }
        ) {
            if (!guidChuyenDi) {
                throw new Error("Không có guidChuyenDi");
            }
            if (!soDienThoai) {
                throw new Error("Không có soDienThoai");
            }
            let rs = await $Core.Api.DiemXuongCuaKhachHang(
                window.$i18n.global.t(
                    "url.GoiYDanhSachDiemXuongTheoSoDienThoaiVaChuyenDi"
                ),
                { guidChuyenDi: guidChuyenDi, soDienThoai: soDienThoai }
            ).Get();
            return rs;
        },
        async layChuyenDiGanDay(state) {
            let rs = await $Core.Api.ChuyenDi(
                window.$i18n.global.t("url.TimKiemChuyenDiGanDay")
            ).Get();
            if (rs.StatusCode == 200) {
                state.commit("setChuyenDiGanDay", rs.Data.data);
            }
            return rs;
        },
        async LayThongTinThemCuaChuyenDi(state, { GuidChuyenDi }) {
            let rs = await $Core.Api.Make(
                window.$i18n.global.t("url.LayThongTinThemCuaChuyenDi"),
                {
                    GuidChuyenDi: GuidChuyenDi,
                }
            ).Get();
            return rs;
        },
        async LayThongTinChuyenDiTheoMaChuyenDi(
            state,
            { MaChuyenDi, GuidChuyenDi }
        ) {
            this.startLoading();
            let rs = await $Core.Api.Make(
                window.$i18n.global.t("url.LayThongTinChuyenDiTheoMaChuyenDi"),
                {
                    MaChuyenDi: MaChuyenDi,
                    GuidChuyenDi: GuidChuyenDi,
                }
            ).Get();
            this.stopLoading();
            return rs;
        },
        async LayThongTinTaiKhoanThanhToan(state) {
            let rs = await $Core.Api.QuanLyThongTinVe(
                window.$i18n.global.t("url.LayThongTinTaiKhoanThanhToan")
            ).Get();
            return rs;
        },
    },
};
